import React from "react";
import { motion } from "framer-motion";

const HighlightImage = ({ ref, fadeIn, inView, src, title, coordinates, number, actualTitle }) => {
    return (
        <motion.div
            className="piece"
            ref={ref}
            animate={{
                ...fadeIn,
                opacity: inView ? 1 : 0,
                x: inView ? 0 : 0,
            }}
        >
            <div className="piece-number">{actualTitle}</div>
            <motion.img src={require(`../images/${src}`)} className={number === 3 ? "large-highlight-image" : "highlight-image"} />
            <motion.div exit={{ opacity: 0 }} className="piece-info">
                <div className="title">{title}</div>
                <div className="title">{coordinates[0]}</div>
                <div className="title">{coordinates[1]}</div>
            </motion.div>
        </motion.div>
    );
};

export default HighlightImage;
