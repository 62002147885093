import React, { useEffect, useState } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";

//Components
import ScrollForMore from "../components/scrollForMore";
import { useScroll } from "../hooks/useScroll";
import Highlights from "../components/highlights";
//Ease
const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };

const firstName = {
    initial: {
        y: 0,
    },
    animate: {
        y: 0,
        transition: {
            delayChildren: 0.6,
            staggerChildren: 0.04,
            staggerDirection: -1,
        },
    },
};

const lastName = {
    initial: {
        y: 0,
    },
    animate: {
        y: 0,
        transition: {
            delayChildren: 0.6,
            staggerChildren: 0.04,
            staggerDirection: 1,
        },
    },
};

const letter = {
    initial: {
        y: 400,
    },
    animate: {
        y: 0,
        transition: { duration: 1, ...transition },
    },
};

const backgroundImageStyling = {
    position: "absolute",
    height: "100vh",
    width: "100%",
    opacity: "1",
    objectFit: "cover",
};

const Model = ({ imageDetails }) => {
    const { scrollYProgress } = useViewportScroll();
    const scale = useTransform(scrollYProgress, [0, 1], [1, 1.1]);

    const scrollY = useScroll();

    const [canScroll, setCanScroll] = useState(false);

    useEffect(() => {
        if (canScroll === false) {
            document.querySelector("body").classList.add("no-scroll");
        } else {
            document.querySelector("body").classList.remove("no-scroll");
        }
    }, [canScroll]);

    return (
        <motion.div onAnimationComplete={() => setCanScroll(true)} className="single" initial="initial" animate="animate" exit="exit">
            <motion.div>
                <motion.img
                    src={require("../images/Top page-background-Transparent.png")}
                    alt="an image"
                    style={{ ...backgroundImageStyling }} // change from 1.1 to 1.0
                    initial={{
                        y: "150%",
                    }}
                    animate={{
                        y: 50,
                        width: "100%",
                        height: "100vh",
                        transition: { delay: 0.2, ...transition },
                        opacity: "0.8",
                    }}
                />
            </motion.div>
            <div className="container fluid">
                <div className="row center top-row">
                    <div className="top">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{
                                opacity: 1,
                                y: 0,
                                transition: { delay: 1.2, ...transition },
                            }}
                            className="details"
                        >
                            <div className="location">
                                <span>63.43024927</span>
                                <span>10.43019938</span>
                            </div>
                            <div className="mua">
                                INSTAGRAM:
                                <a href="https://www.instagram.com/er.w.ik/" style={{ textDecoration: "none", color: "#FF6C1D" }}>
                                    {" "}
                                    @er.w.ik
                                </a>
                            </div>
                        </motion.div>
                        <motion.div className="model">
                            <motion.span className="first" variants={firstName}>
                                <motion.span variants={letter}>E</motion.span>
                                <motion.span variants={letter}>r</motion.span>
                                <motion.span variants={letter}>l</motion.span>
                                <motion.span variants={letter}>e</motion.span>
                                <motion.span variants={letter}>n</motion.span>
                                <motion.span variants={letter}>d</motion.span>
                            </motion.span>
                            <motion.span className="last" variants={lastName}>
                                <motion.span variants={letter}>W</motion.span>
                                <motion.span variants={letter}>i</motion.span>
                                <motion.span variants={letter}>k</motion.span>
                            </motion.span>
                        </motion.div>
                    </div>
                </div>
                <div className="row bottom-row">
                    <div className="bottom">
                        <motion.div className="image-container-single">
                            <motion.div
                                initial={{
                                    y: "-50%",
                                    width: imageDetails.width,
                                    height: imageDetails.height,
                                }}
                                animate={{
                                    y: 0,
                                    width: "90%",
                                    height: window.innerWidth > 1440 ? 800 : 382,
                                    transition: { delay: 0.2, ...transition },
                                }}
                                className="thumbnail-single"
                            >
                                {/* //TODO Load bildet på forhånd så det ikke trenger å loade på load (tar) */}
                                <motion.div className="frame-single" whileHover="hover" transition={transition}>
                                    <motion.img
                                        src={require("../images/Paintings_compressed/Front Painting.png")}
                                        alt="an image"
                                        style={{ scale: /* scale */ 0.98 }} // change from 1.1 to 1.0
                                        initial={{ scale: 1.1 }}
                                        animate={{
                                            transition: { delay: 0.2, ...transition },
                                            y: window.innerWidth > 1440 ? 0 : 0,

                                            scale: 0.98, // add this line to end the transition with a scale of 0.98
                                        }}
                                    />
                                </motion.div>
                            </motion.div>
                        </motion.div>
                    </div>
                    {scrollY < 200 && <ScrollForMore />}
                </div>
            </div>
            <Highlights scrollY={scrollY} />
        </motion.div>
    );
};

export default Model;
