import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import HighlightImage from "./highlightImage";
import AboutMe from "./aboutMe";

const Highlights = ({ scrollY }) => {
    const { ref, inView } = useInView({
        threshold: 0.8,
        triggerOnce: true,
    });

    const fadeIn = {
        transition: { duration: 1 },
    };

    return (
        <>
            <div className="detailed-information" style={{ position: "relative", zIndex: 1, top: 0 - scrollY / 1.4 }}>
                <div className="container">
                    <div className="row">
                        <motion.div
                            className="title"
                            ref={ref}
                            animate={{
                                ...fadeIn,
                                opacity: inView ? 1 : 0,
                                x: inView ? 0 : -50,
                            }}
                        >
                            <h2>Highlighted Pieces</h2>
                        </motion.div>
                    </div>
                    <div className="piece-grid">
                        <HighlightImage
                            ref={ref}
                            fadeIn={fadeIn}
                            inView={inView}
                            src={"Paintings/FRoaf.png"}
                            title="Oil on canvas panel"
                            actualTitle="Sleeping In"
                            coordinates={["50x65cm", "March 2023"]}
                            number={1}
                        />
                        <HighlightImage
                            ref={ref}
                            fadeIn={fadeIn}
                            inView={inView}
                            src={"Paintings/Oil paint 1.png"}
                            title="Oil on canvas panel"
                            actualTitle="Tranquility"
                            coordinates={["35x35cm", "November 2022"]}
                            number={2}
                        />
                        <HighlightImage
                            ref={ref}
                            fadeIn={fadeIn}
                            inView={inView}
                            src={"Paintings/Gentle Grasp.jpg"}
                            title="Acrylics on canvas"
                            actualTitle="Gentle Lift"
                            coordinates={["90x120cm", "April 2022"]}
                            number={3}
                        />
                        <Link to={"/gallery"} style={{ textDecoration: "none" }}>
                            <div className="show-gallery">Show Gallery</div>
                        </Link>
                    </div>
                </div>
                <AboutMe />
            </div>
        </>
    );
};

export default Highlights;
