import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { useScroll } from "../hooks/useScroll";

const Header = () => {
    const location = useLocation();
    const isModel = location.pathname === "/main" || location.pathname === "/gallery";

    const isGallery = location.pathname === "/gallery";
    const isMain = location.pathname === "/main";

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const scrollY = useScroll();

    return (
        <>
            <header>
                <div
                    className="container"
                    style={
                        scrollY > 100 || (isGallery && scrollY > 256)
                            ? { backgroundColor: "rgba(255, 255, 255, 0.3)", transition: "0.6s ease" }
                            : { backgroundColor: "transparent", transition: "0.6s ease" }
                    }
                >
                    <div className="row space-between">
                        <Link to={`/`} className="logo">
                            <img src={require("../images/shee-removebg-preview.png")} alt="signature" width={60} height={60} />
                            <div>Erlend Wik</div>
                        </Link>
                        {(isModel || (isGallery && scrollY > 256)) && (
                            <motion.div>
                                <motion.img
                                    src={require("../images/Signature.png")}
                                    alt="an image"
                                    className="signature-logo"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ delay: 2.4, duration: 1.5 }}
                                />
                            </motion.div>
                        )}
                        <div className="menu" onClick={() => setIsMenuOpen(true)}>
                            MENU
                        </div>
                    </div>
                </div>
                <div className={isMenuOpen ? "menu-open" : "menu-closed"}>
                    {isMenuOpen && (
                        <>
                            <div className="close" onClick={() => setIsMenuOpen(false)}>
                                Close
                            </div>
                            <Link to={`/`} style={{ textDecoration: "none" }} onClick={() => setIsMenuOpen(false)}>
                                <div className={!(isMain || isGallery) ? "menu-text-active" : "menu-text"}>HOME</div>
                            </Link>
                            <Link to={`/main`} style={{ textDecoration: "none" }} onClick={() => setIsMenuOpen(false)}>
                                <div className={isMain ? "menu-text-active" : "menu-text"}>MAIN</div>
                            </Link>
                            <Link to={`gallery`} style={{ textDecoration: "none" }} onClick={() => setIsMenuOpen(false)}>
                                <div className={isGallery ? "menu-text-active" : "menu-text"}>GALLERY</div>
                            </Link>
                            <div className="footer">
                                Utviklet av{" "}
                                <a
                                    href="https://weblight.no/"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{ textDecoration: "none", color: "black" }}
                                >
                                    Weblight
                                </a>
                            </div>
                        </>
                    )}
                </div>
                {isMenuOpen && (
                    <>
                        <div className="blackout" onClick={() => setIsMenuOpen(false)}></div>
                    </>
                )}
            </header>
        </>
    );
};

export default Header;
