import React, { useState, useEffect } from "react";
import BasicModal from "../components/modal";
const physicalPaintings = require.context("../images/Paintings", false, /\.(png|jpe?g|svg)$/).keys();
const digitalPaintings = require.context("../images/Digital", false, /\.(png|jpe?g|svg)$/).keys();
const allPaintings = physicalPaintings.concat(digitalPaintings);

const Gallery = () => {
    const [chosenFilter, setChosenFilter] = useState("physical");
    const [paintings, setPaintings] = useState(physicalPaintings);
    const [showModal, setShowModal] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const setDigitalPaintings = () => {
        setChosenFilter("digital");
        setPaintings(digitalPaintings);
    };

    const setPhysicalPaintings = () => {
        setChosenFilter("physical");
        setPaintings(physicalPaintings);
    };

    const setAllPaintings = () => {
        setChosenFilter("all");
        setPaintings(allPaintings);
    };

    const decideFolder = () => {
        if (chosenFilter === "physical") {
            return "Paintings";
        } else if (chosenFilter === "digital") {
            return "Digital";
        } else {
            return "AllPaintings";
        }
    };

    const imagePaths = paintings.map((image) => require(`../images/${decideFolder()}/${image.substring(2)}`));
    const largeImagePaths = paintings.map((image) => require(`../images/AllLargePaintings/${image.substring(2)}`));

    const handleImageClick = (index) => {
        setCurrentImageIndex(index);
        setShowModal(true);
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imagePaths.length);
    };

    const handlePrevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + imagePaths.length) % imagePaths.length);
    };

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <>
            <div className="gallery-outer">
                <div className="header">
                    <div onClick={setPhysicalPaintings} className={chosenFilter === "physical" ? "active" : "inactive"}>
                        TRADITIONAL WORKS
                    </div>
                    <div onClick={setDigitalPaintings} className={chosenFilter === "digital" ? "active" : "inactive"}>
                        DIGITAL WORKS
                    </div>
                    <div
                        className={`show-all ${chosenFilter === "all" ? "active" : "inactive"}`}
                        style={{ fontSize: "24px" }}
                        onClick={setAllPaintings}
                    >
                        Show All
                    </div>
                </div>
                <div className="gallery">
                    {imagePaths.map((path, index) => (
                        <div className="img-element" key={index} onClick={() => handleImageClick(index)}>
                            <img src={path} alt="painting" />
                        </div>
                    ))}
                </div>
            </div>
            <BasicModal
                open={showModal}
                imgSrc={largeImagePaths[currentImageIndex]}
                onClose={() => setShowModal(false)}
                onNext={handleNextImage}
                onPrev={handlePrevImage}
            />
        </>
    );
};

export default Gallery;
