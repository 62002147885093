import React, { useState, useEffect } from "react";
import "./contactForm.scss";
import erlendNokia from "../../images/elend-nokia.png";

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
        radioOption: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);

        fetch("https://seilvind-email-server.fly.dev/send-email", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                console.error("There was an error sending the email:", error);
            });
    };

    return (
        <>
            <div className="form-outer">
                <div className="left">
                    <img src={erlendNokia} alt="" className="me" />
                </div>
                <div className="right">
                    <form onSubmit={handleSubmit}>
                        <div className="form-inner">
                            <div className="heading">Kontakt Meg</div>
                            <div className="header">
                                <div className="hover">
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        placeholder="Navn"
                                        className="hover"
                                    />
                                </div>

                                <div className="hover">
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="Email"
                                        className="hover"
                                    />
                                </div>
                            </div>
                            <div className="message-heading">Melding</div>
                            <div className="message-box">
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className="hover"
                                    placeholder="Skriv meldingen din her."
                                ></textarea>
                            </div>

                            <button type="submit" className="submit hover">
                                Send Melding
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ContactForm;
