import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "70%",
    height: "100%",
    transform: "translate(-50%, -50%)",
    bgcolor: "none",
    boxShadow: 24,
    display: "flex",
    justifyContent: "center",
    border: "none",
    outline: "none",
    alignItems: "center",
    padding: 40,
    maxHeight: "90vh", // Set a maximum height to ensure the modal fits within the viewport
    overflowY: "scroll", // Enable scrolling if the image exceeds the modal height
    p: 4,
};

const BasicModal = ({ open, onClose, imgSrc, onNext, onPrev }) => {
    return (
        <div>
            <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <ArrowBackIosNewIcon onClick={onPrev} style={{ color: "white", fontSize: 44, cursor: "pointer" }} />
                    <img src={imgSrc} alt="yeep" className="modal-image" />
                    <ArrowForwardIosIcon onClick={onNext} style={{ color: "white", fontSize: 44, cursor: "pointer" }} />
                </Box>
            </Modal>
        </div>
    );
};

export default BasicModal;
