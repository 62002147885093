import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ProgressiveImage from "react-progressive-image";

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] };

const Home = ({ imageDetails, image }) => {
    useEffect(() => {
        let vh = window?.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }, []);
    return (
        <>
            <main>
                <div className="container">
                    <div className="row center">
                        <div className="image-container">
                            <motion.div exit={{ opacity: 0 }} transition={transition} className="click-me">
                                Click Me
                            </motion.div>
                            <motion.div exit={{ opacity: 0 }} transition={transition} className="signature">
                                <motion.img
                                    src={require("../images/Signature.png")}
                                    className="signature-image"
                                    alt="signature"
                                    transition={transition}
                                />
                            </motion.div>
                            <div
                                className="thumbnail"
                                ref={image}
                                /*  style={{
                                width: imageDetails.width,
                                height: imageDetails.height,
                            }} */
                            >
                                <div className="frame">
                                    <Link to={`/main`}>
                                        <ProgressiveImage
                                            src={require("../images/Paintings/Front Painting.png")}
                                            placeholder={require("../images/Paintings/Front Painting.png")}
                                        >
                                            {(src) => (
                                                <motion.img
                                                    src={src}
                                                    alt="Kul"
                                                    style={{ scale: 0.98 }}
                                                    whileHover={{ scale: 1.1 }}
                                                    transition={transition}
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </Link>
                                </div>
                            </div>
                            <motion.div exit={{ opacity: 0 }} transition={transition} className="information">
                                <div className="title">Oil on canvas</div>
                                <div className="title">April 2022</div>
                                <div className="location">
                                    <span>65 x 50cm</span>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Home;
