import React from "react";
import { Link } from "react-router-dom";

const AboutMe = () => {
    return (
        <>
            <div className="about-me" id="about-me">
                <div className="inner">
                    <div className="portrait-container">
                        <img src={require("../images/elend-nokia.png")} alt="insane" className="portrait-image" />
                    </div>
                    <div className="artist-description">
                        <div className="header">Who is Erlend?</div>
                        <p>
                            I am an emerging artist who has recently embarked on a journey to pursue my passion for art. I was born in Trondheim,
                            Norway and discovered my love for art at a young age. I was fascinated by the world of colors and shapes, so I started
                            experimenting with various artistic mediums, eventually finding my forte in painting.
                        </p>
                        <p>
                            Currently, I am pursuing a degree in art. I have a strong desire to become a full-time artist, so I am working on honing
                            my skills and expanding my repertoire.
                        </p>
                        <p>In my daily life, I find joy and inspiration from my friends, music, gaming, and nature.</p>
                        <Link to={"/contact"} style={{ textDecoration: "none", color: "white" }}>
                            <div className="get-in-touch">Get in touch {" >"}</div>
                        </Link>
                    </div>
                </div>
                <div className="bottom-image">
                    {/*                 <img src={require("../images/Top page-background-Transparent.png")} alt="bottom-img" className="bottom-img" />
                     */}{" "}
                </div>
            </div>
        </>
    );
};

export default AboutMe;
