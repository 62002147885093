import React from "react";
import { AnimatePresence } from "framer-motion";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//Pages
import Home from "./pages/home";
import Model from "./pages/model";
//components
import Header from "./components/header";
//Styles
import "./App.scss";
import Gallery from "./pages/gallery";
import Highlights from "./components/highlights";
import ContactForm from "./components/ContactForm/ContactForm";

function App() {
    const imageDetails = {
        width: 604,
        height: 460,
    };

    return (
        <>
            <link rel="preload" href={require("./images/Paintings_compressed/Front Painting.png")} as="image" />
            <Router>
                <Header />
                <AnimatePresence exitBeforeEnter>
                    <Switch>
                        <Route exact path="/" render={() => <Home imageDetails={imageDetails} />} />
                        <Route exact path="/main" render={() => <Model imageDetails={imageDetails} />} />
                        <Route exact path="/gallery" render={() => <Gallery />} />
                        <Route exact path="/contact" render={() => <ContactForm />} />
                        <Route path="*" component={<Home imageDetails={imageDetails} />} />
                    </Switch>
                </AnimatePresence>
            </Router>
        </>
    );
}

export default App;
